import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`You can hide the content of the map until a selected time. Like this you can
share the map URL and QR-code, but the content is not visible before the
countdown stops.`}</p>
    <br />
    <p>{`Select the "Map countdown" tab and set the time.`}</p>
    <Image src="pro/coming-soon.jpg" mdxType="Image" />
    <br />
    <br />
    <Link className="float-left" to="/pro/mapbysearch" mdxType="Link">
  ← Select actitivites by search
    </Link>
    <Link className="float-right" to="/pro/embed" mdxType="Link">
  Embed a trail map →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      